import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "mapbox-gl/dist/mapbox-gl.css";
import { Link } from "react-router-dom";
import { getAllNations, getAllNationsSuccess } from "../../store/actions";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import { Spinner } from "reactstrap";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Head from "../../components/Head";
mapboxgl.accessToken =
  "pk.eyJ1IjoiemFuZ291bCIsImEiOiJja3ptZXpoNjcwNmkyMm9vMGV6OXZha2NtIn0.sh8oAk-oGJfC58IkNl8IQA";
const Home = ({
  all_nations,
  data_page_components,
  loading,
  error,
  language,
}) => {
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const storedData = localStorage.getItem("all_nations");
    if (storedData) {
      setShowLoading(false);
      dispatch(getAllNationsSuccess(JSON.parse(storedData)));
    } else {
      dispatch(getAllNations());
      setShowLoading(true);
    }
  }, [dispatch]);

  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});

  const mapContainer = useRef(null);
  useEffect(() => {
    if (mapContainer.current && all_nations.length > 0) {
      var initialLongitude = window.innerWidth < 768 ? 5 : 0;
      var map = new mapboxgl.Map({
        container: mapContainer.current,
        style: `${
          language === "fr"
            ? "mapbox://styles/zangoul/clm1wn53i00sf01pebc8h1s0v"
            : "mapbox://styles/zangoul/clseyp8bj03an01pfb3aj2fqe"
        }`,
        center: [initialLongitude, -8],
        zoom: window.innerWidth < 768 ? 1 : 2,
        maxZoom: 20,
        minZoom: 1.8,
      });

      map.on("style.load", () => {
        setShowLoading(false);
        const geojsonObject = {
          type: "FeatureCollection",
          features: all_nations,
        };

        map.addSource("geojson-source", {
          type: "geojson",
          data: geojsonObject,
        });

        map.addLayer({
          id: "geojson-layer",
          type: "fill",
          source: "geojson-source",
          paint: {
            "fill-color": ["get", "hexColorCode"],
            "fill-opacity": 0.7,
            "fill-outline-color": "rgba(200, 100, 240, 0)",
          },
        });

        // Begin Popup
        let currentPopup = null;

        map.on("mousemove", "geojson-layer", (e) => {
          const nationProperties = e.features[0].properties;
          if (currentPopup) {
            currentPopup.remove();
          }

          currentPopup = new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(
              `
          <div className="row g-0" style="display: flex; align-items: center; justify-content: flex-start;">
          <div className="col-md-3 conteneur" style="padding-left: 5px; padding-right: 10px;">
            <img
             src="${nationProperties.nationMapUrl}"
              className="img-fluid rounded-start"
              alt="mini_map"
              width="50px"
              style="background-color: ${nationProperties.hexColorCode}; border-radius: 8px;"
            />
          </div>
          <div className="col-md-9 card-details" >
            <div className="card-body">
              <h5 className="card-title" style="font-size: 12px;">
                Nom:<strong>${nationProperties.nationName}</strong>
              </h5>
              <h5 className="card-title" style="font-size: 12px;">
                Superficie: <strong>${nationProperties.area}</strong>
              </h5>
              <h5 className="card-title" style="font-size: 12px;">
                Pays précedents: <strong>${nationProperties.previousCountries}</strong>
              </h5>
            </div>
          </div>
        </div>
        `
            )
            .addTo(map);
        });

        map.on("mouseleave", "geojson-layer", () => {
          map.getCanvas().style.cursor = "pointer";

          if (currentPopup) {
            currentPopup.remove();
            currentPopup = null;
          }
          // End Popup
        });
      });
      map.addControl(new mapboxgl.NavigationControl());
    }
  }, [all_nations, language]);

  const handleCardClick = (nation) => {
    // Rassemblez toutes les coordonnées de la nation
    const allCoordinates = nation.flatMap((feature) =>
      feature.geometry.coordinates.flatMap((polygon) => polygon)
    );

    // Rafraîchissez la carte avec la nouvelle nation
    if (mapContainer.current && nation.length > 0) {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/zangoul/clm1wn53i00sf01pebc8h1s0v",
        center: [allCoordinates[0][0], allCoordinates[0][1]],
        zoom: 5,
        maxZoom: 20,
        minZoom: 1.8,
      });

      map.on("style.load", () => {
        const geojsonObject = {
          type: "FeatureCollection",
          features: nation.map((feature) => ({
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: feature.geometry.coordinates,
            },
            properties: feature.properties,
          })),
        };

        map.addSource("geojson-source", {
          type: "geojson",
          data: geojsonObject,
        });

        map.addLayer({
          id: "geojson-layer",
          type: "fill",
          source: "geojson-source",
          paint: {
            "fill-color": ["get", "hexColorCode"],
            "fill-opacity": 0.7,
            "fill-outline-color": "rgba(200, 100, 240, 0)",
          },
        });
        // Begin Popup
        let currentPopup = null;

        map.on("mousemove", "geojson-layer", (e) => {
          const nationProperties = e.features[0].properties;
          if (currentPopup) {
            currentPopup.remove();
          }

          currentPopup = new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(
              `
               <div className="row g-0" style="display: flex; align-items: center; justify-content: flex-start;">
               <div className="col-md-3 conteneur" style="padding-left: 5px; padding-right: 10px;">
                 <img
                  src="${nationProperties.nationMapUrl}"
                   className="img-fluid rounded-start"
                   alt="mini_map"
                   width="50px"
                   style="background-color: ${nationProperties.hexColorCode}; border-radius: 8px;"
                 />
               </div>
               <div className="col-md-9 card-details">
                 <div className="card-body">
                   <h5 className="card-title" style="font-size: 12px;">
                     Nation:<strong>${nationProperties.nationName}</strong>
                   </h5>
                   <h5 className="card-title" style="font-size: 12px;">
                     Superficie: <strong>${nationProperties.area}</strong>
                   </h5>
                   <h5 className="card-title" style="font-size: 12px;">
                     Anciens pays: <strong>${nationProperties.previousCountries}</strong>
                   </h5>
                 </div>
               </div>
             </div>
             `
            )
            .addTo(map);
        });

        map.on("mouseleave", "geojson-layer", () => {
          map.getCanvas().style.cursor = "pointer";

          if (currentPopup) {
            currentPopup.remove();
            currentPopup = null;
          }
          // End Popup
        });
      });
    }
  };

  const filteredNations = all_nations
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.properties.nationName === value.properties.nationName
        )
    )
    .filter((uniqueNation) =>
      uniqueNation.properties.nationName
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

  const resetMap = () => {
    const storedNations = JSON.parse(localStorage.getItem("all_nations"));

    if (storedNations) {
      dispatch(getAllNationsSuccess(storedNations));
    } else {
      dispatch(getAllNations());
    }
    setSearchTerm("");
  };

  return (
    <div>
      <Header />
      <Head pageTitle={data_page_components_obj.home} />
      <div id="wrapper">
        <div
          className="main-wrapper"
          style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          <section className="text-data">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="fw-bold pt-3 report-title ff2">
                    Africa Nation
                  </h2>
                </div>
              </div>
            </div>
          </section>

          <section className="modboxwrapper">
            <div className="container">
              {/* /.modbox  */}
              <div className="modbox mt-4">
                <div className="row">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control search"
                          data-i18n-placeholder="search_nation"
                          aria-label="Filtrer par nation"
                          aria-describedby="basic-addon2"
                          id="nation-filter"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="reset-button"
                            data-i18n="reset_map"
                            onClick={resetMap}
                          >
                            {data_page_components_obj.resetMap}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-sm-3 sidebar"
                    style={{ backgroundColor: "#f0f8ff" }}
                  >
                    {filteredNations.length === 0 ? (
                      <p style={{ color: "red" }}>
                        {" "}
                        {data_page_components_obj.searchNationNotFound}
                      </p>
                    ) : (
                      <div>
                        {filteredNations.map((uniqueNation, index) => (
                          <div
                            className="card m-2"
                            id="card-container"
                            key={uniqueNation.id}
                            onClick={() =>
                              handleCardClick(
                                all_nations.filter(
                                  (n) =>
                                    n.properties.nationName ===
                                    uniqueNation.properties.nationName
                                )
                              )
                            }
                          >
                            <div
                              className="side_row"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div className="col-md-3 conteneur">
                                <img
                                  src={uniqueNation.properties.nationMapUrl}
                                  className="img-fluid rounded-start"
                                  alt="mini_map"
                                  style={{
                                    backgroundColor: `${uniqueNation.properties.hexColorCode}`,
                                    width: "50px",
                                  }}
                                />
                              </div>
                              <div className="col-md-9 card-details">
                                <div
                                  className="card-body"
                                  style={{ padding: "10px" }}
                                >
                                  <h5 className="card-title">
                                    {data_page_components_obj.tableNationName}:{" "}
                                    <span id="nationName">
                                      <strong>
                                        {uniqueNation.properties.nationName}
                                      </strong>
                                    </span>
                                  </h5>
                                  <h5 className="card-title">
                                    {data_page_components_obj.area}:{" "}
                                    <span id="superficie">
                                      <strong>
                                        {uniqueNation.properties.area}{" "}
                                      </strong>
                                    </span>
                                  </h5>
                                  <h5 className="card-title">
                                    {
                                      data_page_components_obj.tableNationPreviousCountries
                                    }
                                    :{" "}
                                    <span id="anciensPays">
                                      <strong>
                                        {
                                          uniqueNation.properties
                                            .previousCountries
                                        }
                                      </strong>
                                    </span>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="col-sm-9">
                    <div className="btn-download" style={{ display: "none" }}>
                      <Link to="" id="download-button" download="carte.svg">
                        {data_page_components_obj.downloadMap}
                      </Link>
                    </div>
                    {showLoading ? (
                      <div className="loading-screen">
                        <div
                          className="loading-message mr-3"
                          style={{ display: "none" }}
                        >
                          Veuillez patientez pendant le chargement de la map...
                        </div>
                        <Spinner style={{ color: "black" }}></Spinner>
                      </div>
                    ) : (
                      ""
                    )}
                    <div ref={mapContainer} className="map-container"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* /.container */}
          </section>
          {/* /.modboxwrapper */}
        </div>
        {/* /.main-wrapper */}

        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  all_nations: state.NationsMapReducer.all_nations,
  loading: state.NationsMapReducer.loading,
  error: state.NationsMapReducer.error,
  data_page_components: state.DataPageReducer.data_page_components,
  language: state.ComponentsDataReducer.language,
});

export default connect(mapStateToProps)(Home);
