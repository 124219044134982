import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { changeLanguage, logoutUser } from "../store/actions";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { FiMenu } from "react-icons/fi";
import { IoIosCloseCircle } from "react-icons/io";
import { LuUserCircle2 } from "react-icons/lu";
import Cookies from "js-cookie";

const Header = ({
  data_components,
  data_page_components,
  loading,
  error,
  language,
  dispatch,
  user,
}) => {
  const handleLanguageChange = (newLanguage) => {
    dispatch(changeLanguage(newLanguage));
    localStorage.setItem("selectedLanguage", newLanguage);
    // Forcer un re-rendu en changeant un état
    // setIsTextDataLoaded((prevState) => !prevState);
  };
  const [isTextDataLoaded, setIsTextDataLoaded] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const token = localStorage.getItem("authToken");
  const [isProfile, setIsProfile] = useState(false);
  const closeNav = () => {
    setIsMobileNavOpen(false);
  };
  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };
  const navigate = useNavigate();

  const checkUser = Cookies.get("checkProfile");
  useEffect(() => {
    if (checkUser) {
      setIsProfile(true);
    }
  }, [checkUser]);

  useEffect(() => {
    if (data_components) {
      setIsTextDataLoaded(true);
    }
  }, [data_components, isTextDataLoaded]);

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleLogout = () => {
    closeNav();
    dispatch(logoutUser({ navigate }));
  };
  useEffect(() => {
    closeNav();
  }, []);
  // Convertir data_page_components en un objet
  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item?.value;
    return obj;
  }, {});

  function getContentByKey(key) {
    const item = data_components.find((item) => item.key === key);
    return item?.contents[0];
  }

  return (
    <div id="wrapper">
      <header className="">
        <div className="header-section">
          <div className="header-top">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 d-xl-flex align-items-center justify-content-between justify-content-xl-start py-0 py-xl-1 px-2">
                  <div className="top-left d-flex align-items-center justify-content-between justify-content-xl-start">
                    <div className="header_logo_left">
                      <Link className="d-block" to="/">
                        <img
                          className="lazy"
                          src="assets/amulab2/images/amulab-logo-svg.svg"
                          alt="amulab-logo"
                          title="Amulab-Logo"
                        />
                      </Link>
                    </div>
                    <div className="header-drop ms-0 ms-lg-4 text-end text-xl-start">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle text-start p-0 logo-select"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Africa Must Unite
                          <span className="d-block fw-bold">Laboratory</span>
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <Link className="dropdown-item" to="/">
                              Laboratoire
                              <span className="d-block fw-bold">
                                Africa Must Unite
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="top-right d-flex align-items-center justify-content-center justify-content-xl-end">
                    <ul className="list d-flex align-items-center d-none d-xl-flex">
                      {(!token || token === "undefined") && (
                        <>
                          <li>
                            <Link
                              className="btn btn-primary"
                              id="login"
                              to="https://account.duegho.com/auth/login/bd84403b2552478f9e535090b5c6af1b"
                              // to="/login"
                            >
                              {data_page_components_obj.login}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="https://account.duegho.com/auth/register/bd84403b2552478f9e535090b5c6af1b"
                              // to="https://account.duegho.com/register"
                              title="Register"
                              id="register"
                            >
                              {data_page_components_obj.register}
                            </Link>
                          </li>
                        </>
                      )}
                      {token && token !== "undefined" && (
                        <li>
                          {" "}
                          <div className="account d-none d-xl-flex">
                            <div className="dropdown">
                              <button
                                className="btn dropdown-toggle d-flex align-items-center justify-content-start"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                id="language"
                              >
                                <LuUserCircle2 size={50} />
                              </button>
                              <ul className="dropdown-menu">
                                {isProfile ? (
                                  <li>
                                    <button
                                      onClick={() =>
                                        handleNavigation(`/${checkUser}`)
                                      }
                                      className="dropdown-item style_account"
                                      title="Profile"
                                    >
                                      {data_page_components_obj.profile}
                                    </button>
                                  </li>
                                ) : (
                                  ""
                                )}

                                <li>
                                  <button
                                    className="dropdown-item style_account"
                                    title="Déconnexion"
                                    onClick={handleLogout}
                                  >
                                    {data_page_components_obj.logout}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>{" "}
                        </li>
                      )}
                    </ul>

                    <div className="lag-select d-none d-xl-flex">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle d-flex align-items-center justify-content-start"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          title="Language"
                          id="language"
                        >
                          {language === "fr"
                            ? data_page_components_obj.languageFrench
                            : data_page_components_obj.languageEnglish}
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <button
                              to="#"
                              className="dropdown-item"
                              title="English"
                              id="en_langue"
                              onClick={() => handleLanguageChange("en")}
                            >
                              {data_page_components_obj.languageEnglish}
                            </button>
                          </li>
                          <li>
                            <button
                              to="#"
                              className="dropdown-item"
                              title="French"
                              id="fr_langue"
                              onClick={() => handleLanguageChange("fr")}
                            >
                              {data_page_components_obj.languageFrench}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-bg">
            <div className="container">
              <div className="row">
                <div className="header-part col-sm-12 p-0">
                  <div className="win-header d-md-flex">
                    <div className="wins_menu_right d-flex align-items-center justify-content-between w-100 align_mobile">
                      <div className="menu_open_close text-right">
                        <FiMenu
                          size={50}
                          className="menu__open"
                          onClick={toggleMobileNav}
                        />
                      </div>
                      <ul className="list d-flex align-items-center d-xl-none">
                        {token === "undefined" ||
                          (!token && (
                            <>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  data-bs-target="#exampleModal"
                                  to="https://account.duegho.com/auth/login/bd84403b2552478f9e535090b5c6af1b"
                                  // to="/login"
                                >
                                  {data_page_components_obj.login}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="https://account.duegho.com/auth/register/bd84403b2552478f9e535090b5c6af1b"
                                  // to="https://account.duegho.com/register"
                                  title="Register"
                                >
                                  {data_page_components_obj.register}
                                </Link>
                              </li>
                            </>
                          ))}
                        {token && (
                          <li>
                            <div className="account d-xl-flex">
                              <div className="dropdown">
                                <button
                                  className="btn dropdown-toggle d-flex align-items-center justify-content-start"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  title="Language"
                                  id="language"
                                >
                                  <LuUserCircle2
                                    size={40}
                                    color="white"
                                    style={{
                                      marginTop: "5px",
                                      marginRight: "-15px",
                                    }}
                                  />
                                </button>
                                <ul className="dropdown-menu">
                                  {isProfile ? (
                                    <li>
                                      <button
                                        onClick={() =>
                                          handleNavigation(`/${checkUser}`)
                                        }
                                        className="dropdown-item style_account"
                                        title="Profile"
                                      >
                                        {data_page_components_obj.profile}
                                      </button>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  <li>
                                    <button
                                      className="dropdown-item style_account"
                                      onClick={() => handleLogout()}
                                    >
                                      {data_page_components_obj.logout}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>

                      {/* <div className="nav-overlay" onclick="closeNav()"></div> */}
                    </div>
                  </div>
                  {isMobileNavOpen ? (
                    <div className="mobile-menu">
                      <div className="nav-menu-left w-100">
                        <nav className="menu_1" id="menu">
                          <div className="menu_mobile_visibility d-flex align-items-center justify-content-between p-2">
                            <div className="logo-main">
                              <Link className="d-block" to="#">
                                <img
                                  className="lazy"
                                  src="assets/amulab2/images/amulab-logo-svg.svg"
                                  alt="amulab-logo"
                                  title="amulab-logo"
                                />
                              </Link>
                            </div>
                            <div className="logo-close">
                              <IoIosCloseCircle
                                onClick={closeNav}
                                size={40}
                                color="#002d56"
                              />
                            </div>
                          </div>
                          <div className="lag-select d-flex justify-content-center d-block d-xl-none mb-4">
                            <div className="dropdown">
                              <button
                                className="btn dropdown-toggle d-flex align-items-center justify-content-start"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                title="Language"
                              >
                                {language === "fr"
                                  ? data_page_components_obj.languageFrench
                                  : data_page_components_obj.languageEnglish}
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <button
                                    className="dropdown-item"
                                    id="en-button"
                                    title="English"
                                    onClick={() => handleLanguageChange("en")}
                                  >
                                    {data_page_components_obj.languageEnglish}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    id="fr-button"
                                    title="French"
                                    onClick={() => handleLanguageChange("fr")}
                                  >
                                    {data_page_components_obj.languageFrench}
                                  </button>
                                </li>
                                <div id="div1"></div>
                              </ul>
                            </div>
                          </div>
                          <ul
                            className="mobile-nav d-lg-flex align-items-lg-center justify-content-lg-between w-100 mx-100"
                            id="menu_ul"
                          >
                            <li className="first-new">
                              {/* <Link to="/" title="Home">
                                {data_components[25]?.contents[0]}
                              </Link> */}
                              <Link to="/" title="Home">
                                {isTextDataLoaded && language === "fr"
                                  ? `${getContentByKey("home-fra") || ""}`
                                  : `${getContentByKey("home-eng") || ""}`}
                              </Link>
                            </li>
                            <li className="first-new">
                              {/* <Link to="/nationslist" title="nation list">
                                {data_components[7]?.contents[0]}
                              </Link> */}
                              <Link to="/nationslist">
                                {isTextDataLoaded && language === "fr"
                                  ? `${
                                      getContentByKey("nations-list-fra") || ""
                                    }`
                                  : `${
                                      getContentByKey("nations-list-eng") || ""
                                    }`}
                              </Link>
                            </li>
                            <li className="first-new">
                              {/* <Link to="/steps" title="steps labo">
                                {data_components[6]?.contents[0]}
                              </Link> */}
                              <Link to="/steps">
                                {isTextDataLoaded && language === "fr"
                                  ? `${getContentByKey("lab-steps-fra") || ""}`
                                  : `${getContentByKey("lab-steps-eng") || ""}`}
                              </Link>
                            </li>
                            <li className="first-new">
                              {/* <Link to="/motto" title="devise labo">
                                {data_components[9]?.contents[0]}
                              </Link> */}
                              <Link to="/motto">
                                {isTextDataLoaded && language === "fr"
                                  ? `${getContentByKey("lab-motto-fra") || ""}`
                                  : `${getContentByKey("lab-motto-eng") || ""}`}
                              </Link>
                            </li>
                            <li className="first-new">
                              {/* <Link to="/agreement" title="agreement">
                                {data_components[10]?.contents[0]}
                              </Link> */}
                              <Link to="/agreement">
                                {isTextDataLoaded && language === "fr"
                                  ? `${
                                      getContentByKey(
                                        "association-agreements-fra"
                                      ) || ""
                                    }`
                                  : `${
                                      getContentByKey(
                                        "association-agreements-eng"
                                      ) || ""
                                    }`}
                              </Link>
                            </li>

                            <li className="first-new">
                              {/* <Link to="/community" title="community">
                                {data_components[14]?.contents[0]}
                              </Link> */}
                              <Link to="/community">
                                {isTextDataLoaded && language === "fr"
                                  ? `${getContentByKey("community-fra") || ""}`
                                  : `${getContentByKey("community-eng") || ""}`}
                              </Link>
                            </li>
                          </ul>
                          <ul className="overflow sub-menu"></ul>
                        </nav>
                      </div>
                    </div>
                  ) : (
                    <div className="nav-menu-left w-100">
                      <nav className="menu" id="menu">
                        <div className="lag-select d-flex justify-content-center d-block d-xl-none mb-4">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle d-flex align-items-center justify-content-start"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              title="Language"
                            >
                              Language
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <button
                                  className="dropdown-item"
                                  id="en-button"
                                  title="English"
                                  onClick={() => handleLanguageChange("en")}
                                >
                                  {data_page_components_obj.languageEnglish}
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  id="fr-button"
                                  title="French"
                                  onClick={() => handleLanguageChange("fr")}
                                >
                                  {data_page_components_obj.languageFrench}
                                </button>
                              </li>
                              <div id="div1"></div>
                            </ul>
                          </div>
                        </div>
                        <ul
                          className="mobile-nav d-lg-flex align-items-lg-center justify-content-lg-between w-100 mx-100"
                          id="menu_ul"
                        >
                          <li className="first-new">
                            {/* <Link to="/">{data_components[25]?.contents[0]}</Link> */}
                            <Link to="/" title="Home">
                              {isTextDataLoaded && language === "fr"
                                ? `${getContentByKey("home-fra") || ""}`
                                : `${getContentByKey("home-eng") || ""}`}
                            </Link>
                          </li>
                          <li className="first-new">
                            {/* <Link to="/nationslist">
                            {data_components[7]?.contents[0]}
                          </Link> */}
                            <Link to="/nationslist">
                              {isTextDataLoaded && language === "fr"
                                ? `${getContentByKey("nations-list-fra") || ""}`
                                : `${
                                    getContentByKey("nations-list-eng") || ""
                                  }`}
                            </Link>
                          </li>
                          <li className="first-new">
                            {/* <Link to="/steps">
                            {data_components[6]?.contents[0]}
                          </Link> */}
                            <Link to="/steps">
                              {isTextDataLoaded && language === "fr"
                                ? `${getContentByKey("lab-steps-fra") || ""}`
                                : `${getContentByKey("lab-steps-eng") || ""}`}
                            </Link>
                          </li>
                          <li className="first-new">
                            {/* <Link to="/motto">
                            {data_components[9]?.contents[0]}
                          </Link> */}
                            <Link to="/motto">
                              {isTextDataLoaded && language === "fr"
                                ? `${getContentByKey("lab-motto-fra") || ""}`
                                : `${getContentByKey("lab-motto-eng") || ""}`}
                            </Link>
                          </li>
                          <li className="first-new">
                            {/* <Link to="/agreement">
                            {data_components[10]?.contents[0]}
                          </Link> */}
                            <Link to="/agreement">
                              {isTextDataLoaded && language === "fr"
                                ? `${
                                    getContentByKey(
                                      "association-agreements-fra"
                                    ) || ""
                                  }`
                                : `${
                                    getContentByKey(
                                      "association-agreements-eng"
                                    ) || ""
                                  }`}
                            </Link>
                          </li>

                          <li className="first-new">
                            {/* <Link to="/community">
                            {data_components[14]?.contents[0]}
                          </Link> */}
                            <Link to="/community">
                              {isTextDataLoaded && language === "fr"
                                ? `${getContentByKey("community-fra") || ""}`
                                : `${getContentByKey("community-eng") || ""}`}
                            </Link>
                          </li>
                        </ul>
                        <ul className="overflow sub-menu"></ul>
                      </nav>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data_components: state.ComponentsDataReducer.data_components,
  loading: state.ComponentsDataReducer.loading,
  error: state.ComponentsDataReducer.error,
  language: state.ComponentsDataReducer.language,
  data_page_components: state.DataPageReducer.data_page_components,
  user: state.LoginReducer.user,
});

export default connect(mapStateToProps)(Header);
